import React from "react";
import { NavLink } from "react-router-dom";
function Service() {
    return(
        <>
    <section id="counts" className="counts animate__animated animate__fadeInDown" style={{backgroundColor:"white"}}>
      <div className="container">

        <div className="row no-gutters">

          <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div className="count-box border p border-2 rounded bg-light text-center">
              <i className="bi bi-emoji-smile"></i>
              <span data-purecounter-start="8" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter">500+</span>
              <p><strong>Happy Clients</strong> </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div className="count-box border p border-2 rounded bg-light text-center">
              <i className="bi bi-journal-richtext"></i>
              <span>8+</span>
              <p><strong>Product</strong> </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div className="count-box border p border-2 rounded bg-light text-center">
              <i className="bi bi-headset"></i>
              <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" className="purecounter">24x7</span>
              <p><strong>Hours Of Support</strong> </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div className="count-box border p border-2 rounded bg-light text-center">
              <i className="bi bi-people"></i>
              <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" className="purecounter">15+</span>
              <p><strong>Smart Workers</strong></p>
            </div>
          </div>

        </div>

      </div>
    </section>
     
    <section id="services" className="services animate__animated animate__fadeInDown" style={{backgroundColor:"white"}}>
      <div className="container">

        <div className="section-title">
          <h2>Services</h2>
          <p></p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 icon-box">
            <div className="rounded service-showd p-2" >
            <div className="icon"><i className="bi bi-briefcase"></i></div>
            <h4 className="title"><NavLink to="#">Best Quality Products</NavLink></h4>
        <p className="description">Latest Corporation always provides best quality kitchen sink to our valuable customer.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 icon-box">
          <div className="rounded service-showd p-2" >
            <div className="icon"><i className="bi bi-card-checklist"></i></div>
            <h4 className="title"><NavLink to="#">International Standard</NavLink></h4>
            <p className="description">We always maintain international standard to our sink.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 icon-box">
          <div className="rounded service-showd p-2" >
            <div className="icon"><i className="bi bi-bar-chart"></i></div>
            <h4 className="title"><NavLink to="#">Long lasting support</NavLink></h4>
            <p className="description">We are having 24x7 Long lasting support that help you always in your problems.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 icon-box">
          <div className="rounded service-showd p-2" >
            <div className="icon"><i className="bi bi-binoculars"></i></div>
            <h4 className="title"><NavLink to="">International market </NavLink></h4>
            <p className="description"> We launched our product in the domestic as well international market </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 icon-box">
          <div className="rounded service-showd p-2" >
            <div className="icon"><i className="bi bi-brightness-high"></i></div>
            <h4 className="title"><NavLink to="">Licensed & Insured </NavLink></h4>
            <p className="description">We have licensed of Our brand and trademark as well.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 icon-box">
          <div className="rounded service-showd p-2" >
            <div className="icon"><i className="bi bi-calendar4-week"></i></div>
            <h4 className="title"><NavLink to="">We Deliver Excellence </NavLink></h4>
            <p className="description">We follow strict industry norms and promise that every product will give you a satisfactory experience. </p>
         </div>
          </div>
        </div>

      </div>
    </section>
        </>
    );
}
export default Service;