import React from 'react';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from "react";

function Footer() {
  const [text, setText] = useState("");
  const HandleOnclick =()=>{
  
   window.open(`https://wa.me/+91${Data.contact_number1}?text=${text}`, '_blank');
   setText("");
   }
   const Handleonchange = (event) => {

       setText(event.target.value);
     }
  const [Data, setData] = useState([]);
  useEffect(() => {
    fetch(
    "https://uniqueadmin.latestcorporation.com/admin_latest_corporation/api/getContact.php",
    
    )
    .then((res) => {
        return res.json();
    })
    .then((res) => {
        setData(res.data[0]);
    });

}, []);

    return(
      <>
    <footer id="footer">
    <div className="footer-top">
      <div className="container animate__animated animate__fadeInDown">
        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="footer-info">
              <h3>LATEST CORPORATION</h3>
              <p>
                {Data.adress}<br/><br/>
                <strong>Phone 1. :</strong> +91 <a href={"tel: +91"+Data.contact_number1} style={{color:"white"}}>{Data.contact_number1} </a> <br/>
                <strong>Phone 2. :</strong> +91 <a href={"tel: +91"+Data.contact_number2} style={{color:"white"}}>{Data.contact_number2} </a> <br/>
                <strong>Phone 3. :</strong> +91 <a href={"tel:+916351314896"} style={{color:"white"}}>6351314896 </a> <br/>
                <strong>Email :</strong> <a href={"mailto:"+Data.gmail} style={{color:"white"}}>{Data.gmail} </a><br/>
              </p>  
              <div className="social-links mt-3">
                <a href={"mailto:"+Data.gmail} style={{color:"white"}}><i className="bx bxl-gmail"></i> </a>
                <a href={"tel:"+Data.contact_number1} style={{color:"white"}}><i className="bi bi-phone"></i></a>
                <a href={"tel:"+Data.contact_number2} style={{color:"white"}}><i className="bi bi-phone"></i></a>
                <a href={"tel:+916351314896"} style={{color:"white"}}><i className="bi bi-phone"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/home" onclick={()=>{
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }} className="scrollto">Home</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/About-us">About us</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Service" >Services</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Team">Team</NavLink></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Products</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <NavLink className="" type='button' onclick={(e)=>{
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
              }} to="/Kitchen-Sink">Kitchen Sink</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Brass">Brass</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/All-Product">All Product</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Contact">Contact</NavLink></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Our Services</h4>
            <p>For direct whatsapp message</p>
            <form action="" method="post">
              <input type="email"  value={text} onChange={Handleonchange} name="email"/>
              <input type="button" value="Send Message"  onClick={HandleOnclick}/>
            </form>

          </div>

        </div>
      </div>
    </div>

        <div className="container">
        <div className="copyright">
            &copy; Copyright <strong><span>LATEST CORPORATION</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
        </div>
        </div>
    </footer>
  </>
    );
}
export default Footer;