import React from "react";
import { useEffect, useState } from "react";
import { NavLink, useParams} from 'react-router-dom';
import ModalImage from "react-modal-image";
function ProductBrasssink() {
  let params = useParams();
  const [data, setData] = useState([]);
  const [Img, setImg] = useState([]);
  const [isLoading, setisLoading] = useState(true);


   useEffect(() => {
      
    fetch(
      "https://uniqueadmin.latestcorporation.com/admin_latest_corporation/api/getperticulerbrass.php?apikey=9537290206&product_id="+params.id,setisLoading(true),
      {
        method: "GET"
      }
      
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data[0]);
        console.log(data);
        setisLoading(false);
      });
  
    });

    useEffect(() => {
        fetch(
        "https://uniqueadmin.latestcorporation.com/admin_latest_corporation/api/getimage.php?product_id="+params.id,
        
        )
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            setImg(res.data);
        });
    
    });



const  imgename = Img.map((cus) =>{
    return (
      <>
              <div className="col-3">
                <div style={{  borderRadius: "25px",border: "5px solid var(--theme-light-color)" }} className="img-fluid m-1">
                <ModalImage small={"https://uniqueadmin.latestcorporation.com/admin_latest_corporation/Images/"+cus.image}
                    large={"https://uniqueadmin.latestcorporation.com/admin_latest_corporation/Images/"+cus.image} alt="Latest Corporation img Brass sink " />
                </div>
              </div>
      </>
    );
  });

    return(
        <>
    <section className="breadcrumbs"style={{marginTop:"115px"}}>
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>  </h2>
          <ol>
            <li><NavLink className="text-theme" to="/Home">Home</NavLink></li>
            <li><NavLink className="text-theme" to="/Brass">Brass Sink</NavLink></li>
            <li>Product</li>
          </ol>
        </div>

      </div>
    </section>

    <section id="portfolio-details" className="portfolio-details">
      <div className="container">
      <div className="section-title">
          <h2>{data.product_name}</h2>
        </div>
        <div className="row">
        <div className="col-lg-1">
          </div>
          <div className="col-lg-10">
            <div className="row">
               {imgename}
              </div>
          </div>

          

        </div>

        <div className="row mt-4 container">
        <div className="col-lg-12 container">
            <div className="portfolio-description">
                <div className="section-title">
                <h2 style={{color:"#444444"}}><u>Description</u></h2>
                <p>
                {data.product_description}
                </p>
                </div>
              <div className="row">
                  
                  <div className="col-lg-6">
                    <div className="row"><h4  className="title text-center"><NavLink><b>Products Range :-  </b></NavLink></h4></div>
                     <div className="row container"> 
                      <div className="col-lg-1"></div>
                        <div className="col-lg-11 mb-1 text-center"> 
                        {data.product_range}
                        <hr/>
                          </div>
                        </div>
                    </div>
                  <div className="col-lg-6">
                    <div className="row "><h4 className="title text-center"><NavLink><b>Product tolerance :- </b></NavLink></h4></div>
                    <div className="row">
                     <div className="col-lg-2"></div>
                        <div className="col-lg-10 text-center"> 
                        {data.product_tolerance}<hr/>
                        </div>
                    </div>
                  </div>
              </div>  
              <div className="row">
                  
                  <div className="col-lg-6">
                    <div className="row"><h4  className="title text-center"><NavLink><b>Product Sample Time  :- </b></NavLink></h4></div>
                     <div className="row container"> 
                      <div className="col-lg-1"></div>
                        <div className="col-lg-11 mb-1 text-center"> 
                        {data.product_sample_time}
                        <hr/>
                          </div>
                        </div>
                    </div>
                  <div className="col-lg-6">
                    <div className="row "><h4 className="title text-center"><NavLink><b>Product Drawing Formate  :- </b></NavLink></h4></div>
                    <div className="row">
                     <div className="col-lg-2"></div>
                        <div className="col-lg-10 text-center"> 
                        {data.product_drawing_formate}<hr/>
                        </div>
                    </div>
                  </div>
              </div>  
              <div className="row">
                  
                  <div className="col-lg-6">
                    <div className="row"><h4  className="title text-center"><NavLink><b>Product Threads :- </b></NavLink></h4></div>
                     <div className="row container"> 
                      <div className="col-lg-1"></div>
                        <div className="col-lg-11 mb-1 text-center"> 
                        {data.product_threads}
                        <hr/>
                          </div>
                        </div>
                    </div>
                  <div className="col-lg-6">
                    <div className="row "><h4 className="title text-center"><NavLink><b>Product Surface Treatment :- </b></NavLink></h4></div>
                    <div className="row">
                     <div className="col-lg-2"></div>
                        <div className="col-lg-10 text-center"> 
                        {data.product_surface_treatment}<hr/>
                        </div>
                    </div>
                  </div>
              </div>  
              <div className="row">
                  
                  <div className="col-lg-6">
                    <div className="row"><h4  className="title text-center"><NavLink><b>Product Packaging :-</b></NavLink></h4></div>
                     <div className="row container"> 
                      <div className="col-lg-1"></div>
                        <div className="col-lg-11 mb-1 text-center"> 
                        {data.product_packaging}
                        <hr/>
                          </div>
                        </div>
                    </div>
                  <div className="col-lg-6">
                    <div className="row "><h4 className="title text-center"><NavLink><b>Product Shipping :- </b></NavLink></h4></div>
                    <div className="row">
                     <div className="col-lg-2"></div>
                        <div className="col-lg-10 text-center"> 
                        {data.product_shipping}<hr/>
                        </div>
                    </div>
                  </div>
              </div>  
            </div>
          </div>
        </div>

      </div>
    </section>
        </>
    );
}
export default ProductBrasssink;