import React from 'react';
import ReactDOM from 'react-dom/client';
import Footer from './Pages/Footer';
import Heder from './Pages/Heder';
import Home from './Pages/Home';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Kichansink from './Pages/Kichansink';
import Brass from './Pages/Brass';
// import Aboutus from './Pages/Aboutus';
import Team from './Pages/Team';
import About from './Pages/About';
import Contact from './Pages/Contact';
import ProductKichansink from './Pages/ProductKichansink';
import Service from './Pages/Service';
import ProductBrasssink from './Pages/ProductBrasssink';

// const root = ReactDOM.createRoot(document.getElementById('root'));

function Layout(){
  return(
      <>
          <Heder/>
          <Outlet/>
          <Footer/>
      </>
  );
}

// const App = () => (

// );

// ReactDOM.render(
//   <App />,
//   document.getElementById('root')
// );
export default function App() {
  return (
      <BrowserRouter>
      <Routes basename="/" > 
            <Route  element={<Layout />}>
              <Route index path='/'  element={<Home/>}/>
              <Route  path='/Kitchen-Sink' element={<Kichansink/>}/>
              <Route  path='/All-Product' element={<><Kichansink/> <Brass/></>}/>
              <Route  path='/Kitchen-Sink/ProductKichansink/:id' element={<ProductKichansink/>}/>
              <Route  path='/Brass/ProductBrasssink/:id' element={<ProductBrasssink/>}/>
              <Route  path='/Brass' element={<Brass/>}/>
              <Route  path='/About-us' element={<About/>}/>
              <Route  path='/Contact' element={<Contact/>}/>
              <Route  path='/Home' element={<Home/>}/>
              <Route  path='/Team' element={<Team/>}/>
              <Route  path='/Service' element={<Service/>}/>
              {/* /Kichan-sink  ProductKichansink*/}
            </Route>
      </Routes>
    </BrowserRouter>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
// root.render(
//   <BrowserRouter>
//   <Routes basename="/" > 
//         <Route  element={<Layout />}>
//           <Route index path='/'  element={<Home/>}/>
//           <Route  path='/Kitchen-Sink' element={<Kichansink/>}/>
//           <Route  path='/All-Product' element={<><Kichansink/> <Brass/></>}/>
//           <Route  path='/Kitchen-Sink/ProductKichansink/:id' element={<ProductKichansink/>}/>
//           <Route  path='/Brass/ProductBrasssink/:id' element={<ProductBrasssink/>}/>
//           <Route  path='/Brass' element={<Brass/>}/>
//           <Route  path='/About-us' element={<About/>}/>
//           <Route  path='/Contact' element={<Contact/>}/>
//           <Route  path='/Home' element={<Home/>}/>
//           <Route  path='/Team' element={<Team/>}/>
//           <Route  path='/Service' element={<Service/>}/>
//           {/* /Kichan-sink  ProductKichansink*/}
//         </Route>
//   </Routes>

// </BrowserRouter>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
