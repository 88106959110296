import React from "react";
import { NavLink } from "react-router-dom";
import Aboutus from "./Aboutus";
function About() {
    return(
        <>
    <section className="breadcrumbs"style={{marginTop:"115px"}}>
      <div className="container">

        <div className="d-flex justify-content-between align-items-center animate__animated animate__jackInTheBox">
          <h2> About </h2>
          <ol>
            <li><NavLink className="text-theme" to="/Home">Home</NavLink></li>
            <li>About</li>
          </ol>
        </div>

      </div>
    </section>
    <Aboutus/>
        </>
    );
}
export default About;