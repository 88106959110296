import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

function Heder() {
  const [Data, setData] = useState([]);
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  var x = window.matchMedia("(max-width: 990px)");
  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };
  const scrollto = (el) => {
    let header = select("#header");
    let offset = header.offsetHeight;

    let elementPos = select(el).offsetTop;
    window.scrollTo({
      top: elementPos - offset,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    fetch(
      "https://uniqueadmin.latestcorporation.com/admin_latest_corporation/api/getContact.php"
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data[0]);
      });
  }, []);
  
  return (
    <>
       <header id="header" className="fixed-top d-flex align-items-center mt-3">
        <div className="container rounded p-1"
          style={{
            backgroundColor: "rgb(0 0 0 / 90%)",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 21,
            borderBottomColor: "#3e3e3e !important"
          }}>
          <div className="container d-flex align-items-center justify-content-between ">
            <NavLink to="/" className="logo">
              <img
                src="assets/img/logol.png"
                alt=""
                style={{ height: "100%", backgroundColor: "aliceblue" }}
                className="img-fluid"
              />
            </NavLink>

            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <NavLink
                    className="nav-link scrollto"
                    to="/Home"
                    onClick={(e) => {
                      this.topFunction();
                      if (x.matches) {
                        document.getElementById("navbar").classList.toggle("navbar-mobile");
                      }
                    }}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link "
                    to="/About-us"
                    onClick={(e) => {
                      this.topFunction();
                      if (x.matches) {
                        document.getElementById("navbar").classList.toggle("navbar-mobile");
                      }
                    }}
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link"
                    to="/Kitchen-Sink"
                    onClick={(e) => {
                      this.topFunction();
                      if (x.matches) {
                        document.getElementById("navbar").classList.toggle("navbar-mobile");
                      }
                    }}
                  >
                    Kitchen Sink
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link"
                    to="/Brass"
                    onClick={(e) => {
                      this.topFunction();
                      if (x.matches) {
                        document.getElementById("navbar").classList.toggle("navbar-mobile");
                      }
                    }}
                  >
                    Brass
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link "
                    to="/Team"
                    onClick={(e) => {
                      this.topFunction();
                      if (x.matches) {
                        document.getElementById("navbar").classList.toggle("navbar-mobile");
                      }
                    }}
                  >
                    Team
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link "
                    to="/Contact"
                    onClick={(e) => {
                      this.topFunction();
                      if (x.matches) {
                        document.getElementById("navbar").classList.toggle("navbar-mobile");
                      }
                    }}
                  >
                    Contact
                  </NavLink>
                </li>
                <li id="google_translate_element" className="nav-link m-2"></li>
              </ul>

              <div>
                <i
                  className="bi bi-list mobile-nav-toggle"
                  onClick={(e) => {
                    const navbar = document.getElementById("navbar");
                    if (navbar) {
                      navbar.classList.toggle("navbar-mobile");
                    }
                    e.target.classList.toggle("bi-list");
                    e.target.classList.toggle("bi-x");
                    const hash = e.target.hash;
                    if (hash) {
                      e.preventDefault();
                      this.scrollto(hash);
                    }
                  }}
                ></i>
              </div>
            </nav>
            {/* <!-- .navbar --> */}
          </div>
          <div
            className="text-center animate__animated animate__fadeInDown rounded"
            style={{ backgroundColor: "#6c757d", position: "relative", fontSize: ".9rem" }}
          >
            <div style={{ color: "white", fontFamily: "system-ui" }} className="rounded">
              To Speak with us Email or Call On{" "}
              <a
                href={"tel: +91" + Data.contact_number2}
                style={{ color: "white" }}
              >
                +91 {Data.contact_number2}{" "}
              </a>{" "}
              |{" "}
              <a
                href={"tel:+91" + Data.contact_number1}
                style={{ color: "white" }}
              >
                +91 {Data.contact_number1}{" "}
              </a>{" "}
              |{" "}
              <a
                href={"tel:+916351314896"}
                style={{ color: "white" }}
              >
                +91 6351314896
              </a>{" "}
              |{" "}
              <a href={"mailto:" + Data.gmail} style={{ color: "white" }}>
                {Data.gmail}{" "}
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Heder;
