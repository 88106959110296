import React from "react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate} from 'react-router-dom';
function Kichansink() { 
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  // setLoding(false);
   const navigate = useNavigate();

   useEffect(() => {
    
    fetch(
      "https://uniqueadmin.latestcorporation.com/admin_latest_corporation/api/getkitchensink.php?apikey=9537290206",setisLoading(true)
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data);
        
        setisLoading(false);
      });
  
}, []);
var i=1;
const  customerdetails = data.map((cus) =>{
  return (
    <>
            <div className="col-lg-4 col-md-6 portfolio-item ">
              <div className="portfolio-wrap border border-2 rounded p-2">
              {/*  */}
                <img src={"https://uniqueadmin.latestcorporation.com/admin_latest_corporation/Images/" + cus.kproduct_image} className="img-fluid shadow-4" alt="Latest Corporation img kichan sink"/>
                <div className="portfolio-info">
                  <h4>{cus.product_name} {i++}</h4>
                  {/* <p>App</p> */}
                  <div className="portfolio-links">
                    <a target={"_black"} href={"https://uniqueadmin.latestcorporation.com/admin_latest_corporation/Images/" + cus.kproduct_image} data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a>
                    <a title="More Details" onClick={() => { 
                  navigate("/Kitchen-Sink/ProductKichansink/"+cus.product_id)
                }}><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
              <div className="text-center yes" onClick={() => { 
                  navigate("/Kitchen-Sink/ProductKichansink/"+cus.product_id) 
                }}><button type="button" onClick={() => { 
                  navigate("/Kitchen-Sink/ProductKichansink/"+cus.product_id) 
                }} className="yes">Read More</button></div>
            </div>
  
    </>
  );
});

  // const kichansinkdetails = data.data;
    return(
        <>
     {/* <!-- ======= Breadcrumbs ======= --> */}
    <section className="breadcrumbs"style={{marginTop:"115px"}}>
      <div className="container">

        <div className="d-flex justify-content-between align-items-center ">
          <h2> </h2>
          <ol>
            <li><NavLink className="text-theme" to="/Home">Home</NavLink></li>
            <li>Kitchen Sink</li>
          </ol>
        </div>
      </div>
    </section>
      
    <section id="portfolio" className="portfolio" style={{backgroundColor:"white"}}>
      <div className="container">

        <div className="section-title animate__animated animate__jackInTheBox">
          <h2>Kitchen Sink</h2>
          {/* <div className=" row text-center">
            <div className="col-5 mt-4 mt-4">
            </div>
              
              <div class="col-9 mt-3 mt-3 spinner"></div>
              </div> */}
              {isLoading && <div className=" row text-center">
                              <div className="col-6 mt-3 mt-3 mt-3 mt-3"></div>
                                     <div class="col-9 mt-3 mt-3 spinner"></div>
                                          </div> }
        </div>
        
        <div className="row portfolio-container animate__animated animate__fadeInDown">

          
        
        {customerdetails}
        </div>
      </div>
    </section> 
    {/* <!-- End Breadcrumbs --> */}
        </>
    );
}
export default Kichansink;