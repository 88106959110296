import React from 'react';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';

export default function Slider() {
  return (
    <MDBCarousel  className="" showControls showIndicators dark fade >
      
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src='assets\img\slide\a.jpeg'
        alt='...'
      >
        <div className="carousel-content container text-center text-md-left" data-aos="fade-up">
          <h2 className="animate__animated animate__fadeInDown"> </h2>
            <p className="animate__animated animate__fadeInUp"></p>
        </div>
      </MDBCarouselItem>

      <MDBCarouselItem
        className='w-100 d-block'
        itemId={2}
        src='assets\img\slide\b.jpeg'
        alt='...'
      >
        <h5 className='animate__animated animate__fadeInDown'></h5>
        <p className='animate__animated animate__fadeInUp'></p>
      </MDBCarouselItem>

      <MDBCarouselItem
        className='w-100 d-block'
        itemId={3}
        src='assets\img\slide\c.jpg'
        alt='...'
      >
        <h5 className='animate__animated animate__fadeInDown' > </h5>
        <p className='animate__animated animate__fadeInUp'> </p>
      </MDBCarouselItem>
    </MDBCarousel>
  );
}