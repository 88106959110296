import React from "react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
function Brass() {
  let params = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      "https://uniqueadmin.latestcorporation.com/admin_latest_corporation/api/getbrass.php?apikey=9537290206",
      setisLoading(true)
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data);
        setisLoading(false);
      });
  }, []);
  var i = 1;
  const customerdetails = data.map((cus) => {
    return (
      <>
        <div className="col-lg-4 col-md-6 portfolio-item  ">
          {/* <div className="portfolio-wrap">
                  <img src={"https://uniqueadmin.latestcorporation.com/admin_latest_corporation/Images/" + cus.kproduct_image} className="img-fluid shadow-4" alt=""/>
                  <div className="portfolio-info">
                    <h4>{cus.product_name} {i++}</h4> */}
          {/* <p>App</p> */}
          {/* <div className="portfolio-links">
                      <a href={"https://uniqueadmin.latestcorporation.com/admin_latest_corporation/Images/" + cus.kproduct_image} data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a>
                      <a title="More Details" onClick={() => { 
                    navigate("/Kichan-sink/ProductKichansink/"+cus.product_id) 
                    console.log(cus.id)
                  }}><i className="bx bx-link"></i></a>
                    </div>
                  </div>
                </div> */}
          <div className="rounded border" >
            <div className="text-center yes ">
              <button
                type="button"
                onClick={() => {
                  navigate("/Brass/ProductBrasssink/" + cus.product_id);
                }}
                className="yes"
              >
                {" "}
                {cus.product_name}
              </button>
            </div>
            <div className="container  rounded bg-light">
              <p className="m-0">{cus.product_sample_time}</p>
            </div>
          </div>
        </div>
      </>
    );
  });
  return (
    <>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs" style={{ marginTop: "115px" }}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2> </h2>
            <ol>
              <li>
                <NavLink className="text-theme" to="/Home">
                  Home
                </NavLink>
              </li>
              <li>Brass</li>
            </ol>
          </div>
        </div>
      </section>

      <section
        id="portfolio"
        className="portfolio"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <div className="section-title animate__animated animate__jackInTheBox">
            <h2>Brass</h2>
            {isLoading && (
              <div className=" row text-center">
                <div className="col-6 mt-3 mt-3 mt-3 mt-3"></div>
                <div class="col-9 mt-3 mt-3 spinner"></div>
              </div>
            )}
          </div>

          <div className="row portfolio-container animate__animated animate__fadeInDown">
            {customerdetails}
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
    </>
  );
}
export default Brass;
