import React from "react";
import { useEffect, useState } from "react";
import { NavLink, useParams} from 'react-router-dom';
function ProductKichansink() {
  let params = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);

   useEffect(() => {
      
    fetch(
      "https://uniqueadmin.latestcorporation.com/admin_latest_corporation/api/getperticulerkitchensink.php?apikey=9537290206&product_id="+params.id,setisLoading(false),
      {
        method: "GET"
      }
      
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data[0]);
        console.log(data)
        setisLoading(true);
      });
  
});
    return(
        <>
 
    <section className="breadcrumbs"style={{marginTop:"115px"}}>
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>     </h2>
          <ol>
            <li><NavLink className="text-theme" to="/Home">Home</NavLink></li>
            <li><NavLink className="text-theme" to="/Kitchen-Sink">Kitchen Sink</NavLink></li>
            <li>Product</li>
          </ol>
        </div>

      </div>
    </section>

    <section id="portfolio-details" className="portfolio-details">
      <div className="container">

        <div className="row">
        <div className="col-lg-2">
          </div>
          <div className="col-lg-4">
          <img src={"https://uniqueadmin.latestcorporation.com/admin_latest_corporation/Images/" + data.kproduct_image} className="img-fluid " alt="Latest Corporation img kichan sink"/>
          </div>

          <div className="col-lg-4">
            <div className="portfolio-info">
              <h3><u style={{color:"#5c9f24"}}></u>{data.product_name}</h3>
              <ul>
                <li  style={{color:"var(--theme-light-color)"}}><strong style={{color:"#444444"}}>SIZE</strong>: {data.kproduct_size}</li>
                <li style={{color:"var(--theme-light-color)"}}><strong style={{color:"#444444"}}>Weight</strong>: {data.kproduct_weight}</li>
                <hr style={{color:"#5c9f24"}}/>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-4 container">
        <div className="col-lg-12 container">
            <div className="portfolio-description">
                <div className="section-title">
                <h2 style={{color:"#444444"}}><u><NavLink>Dimensions</NavLink></u></h2>
                </div>

                <div className="row">
                  
                  <div className="col-lg-8">
                    <div className="row"><h3  className="title text-center"><NavLink></NavLink></h3></div>
                     <div className="row container"> 
                      <div className="col-lg-1"></div>
                        <div className="col-lg-11 mb-1"> 
                          <img src={data.kproduct_dimensions} className="img-fluid " alt="Latest Corporation img kitchan sink Dimensions"/>
                          {/* G:\Latest Corporation\main-panale\public\assets\img\g.png */}
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 container">
        <div className="col-lg-12 container">
            <div className="portfolio-description">
                <div className="section-title">
                <h2 style={{color:"#444444"}}><u>Colour / Shade</u></h2>
                </div>

                <div className="row">
                  
                  <div className="col-lg-6">
                    <div className="row"><h3  className="title text-center"><NavLink>Granite Series </NavLink></h3></div>
                     <div className="row container"> 
                      <div className="col-lg-1"></div>
                        <div className="col-lg-11 mb-1"> 
                          <img src="https://www.quazitosink.com/wp-content/uploads/2022/04/granite-color-1024x244.png" className="img-fluid " alt="Latest Corporation img kitchan sink colour"/>
                          {/* G:\Latest Corporation\main-panale\public\assets\img\g.png */}
                          </div>
                        </div>
                    </div>
                  <div className="col-lg-6">
                    <div className="row"><h3 className="title text-center"><NavLink>Metallic Series</NavLink></h3></div>
                    <div className="row">
                     <div className="col-lg-2"></div>
                        <div className="col-lg-10"> 
                          <img src="https://www.quazitosink.com/wp-content/uploads/2022/04/metalic-color-1024x280.png" className="img-fluid " alt="Latest Corporation img kichan sink colour"/>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

      </div>
    </section>
   
        </>
    );
}
export default ProductKichansink;