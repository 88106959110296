import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
function Contact() {
  var myObj = document.getElementById("smsg");
  function msg(){
    myObj.style.display = "block";
    
    myObj.classList.add('animate__jackInTheBox');
    var name=document.getElementById ("name");
    name.value="";
    var email=document.getElementById ("email");
    email.value="";
    var subject=document.getElementById ("subject");
    subject.value="";
    var msg=document.getElementById ("msg");
    msg.value="";
    setTimeout(myFunction, 4000)
  }
  function myFunction() {
    myObj.style.display = "none";
  }
  const [data, setData] = useState([]);
  const [Info, setInfo] = useState([]);
  useEffect(() => {
    fetch(
    "https://uniqueadmin.latestcorporation.com/admin_latest_corporation/api/getContact.php",
    
    )
    .then((res) => {
        return res.json();
    })
    .then((res) => {
      console.log(res[0]);
        setInfo(res.data[0]);
    });

}, []);

    return(
        <>
    <section   className="breadcrumbs"style={{marginTop:"115px"}}>
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2> </h2>
          <ol>
            <li><NavLink className="text-theme" to="/Home">Home</NavLink></li>
            <li>Contact</li>
          </ol>
        </div>

      </div>
    </section>
    <section id="contact" className="contact" >
      <div className="container" data-aos="fade-up">

        <div className="section-title animate__animated animate__jackInTheBox" data-aos="zoom-in">
          <h2>Contact</h2>
        </div>

        <div className="row contact-info animate__animated animate__fadeInDown">
          <div className="col-md-4">
            <div className="contact-address">
              <i className="bi bi-geo-alt"></i>
              <h3>Address</h3>
              <address>{Info.adress}</address>
            </div>
          </div>

          <div className="col-md-4  ">
            <div className="contact-phone">
              <i className="bi bi-phone"></i>
              <h3>Phone Number</h3>
              <p><a href={"tel:+91"+Info.contact_number1}>+91 {Info.contact_number1}</a></p>
              <p><a href={"tel:+91"+Info.contact_number2}>+91 {Info.contact_number2}</a></p>
              <p><a href={"tel:+916351314896"}>+91 6351314896</a></p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-email">
              <i className="bi bi-envelope"></i>
              <h3>Email</h3>
              <p><a href={"mailto:"+Info.gmail}>{Info.gmail}</a></p>
            </div>
          </div>

        </div><div className="form animate__animated animate__fadeInDown">
          <form action="forms/contact.php" method="post" className="php-email-form">
            <div className="row">
              <div className="col-md-6 form-group">
                <input type="text" name="name" className="form-control" onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }} id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" required/>
              </div>
              <div className="col-md-6 form-group mt-3 mt-md-0">
                <input type="email" className="form-control" value={data.email}  onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }} name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" required/>
              </div>
            </div>
            <div className="form-group mt-3">
              <input type="text" onChange={(e) => {
                setData({ ...data, subject: e.target.value });
              }} className="form-control" value={data.subject} name="subject" id="subject" placeholder="Subject" required/>
            </div>
            <div className="form-group mt-3">
              <textarea className="form-control"  onChange={(e) => {
                setData({ ...data, message: e.target.value });
              }} name="message" rows="5" id="msg" placeholder="Message" required></textarea>
            </div>
            <div className="my-3">
              <div className="loading">Loadinghg</div>
              <div className="error-message"></div>
              <div className="sent-message animate__animated"  id="smsg">Your message has been sent. Thank you!</div>
            </div>
            <div className="text-center"><button type="button"  onClick={(e)=>{
                fetch(
                  "https://uniqueadmin.latestcorporation.com/admin_latest_corporation/api/getfeedback.php?apikey=9537290206" ,
                  {
                    method: "POST",
                    body: JSON.stringify(data),
                    // headers: {
                    //   "Content-Type": "application/json"
                    // }
                  }
                ).then(() => {
                  console.log("msg sent")
                  msg();
                });
           }}>Send Message</button></div>
          </form>
        </div>

      </div>
    </section>
        </>
    );
}
export default Contact;