import React from "react";
import { NavLink} from 'react-router-dom';
function Team() {
    return(
        <>
    <section className="breadcrumbs animate__animated animate__fadeInUp" style={{marginTop:"110px"}} >
      <div className="container">

        <div className="d-flex animate__shakeY justify-content-between align-items-center">
          <h2> </h2>
          <ol>
            <li><NavLink className="text-theme" to="/Home">Home</NavLink></li>
            <li>Team</li>
          </ol>
        </div>

      </div>
    </section>

    <section id="team" className="team" style={{backgroundColor:"white"}}>
      <div className="container">

        <div className="section-title animate__animated animate__jackInTheBox">
          <h2>Team</h2>
          <p>            </p>
        </div>

        <div className="row animate__animated animate__fadeInDown">
          <div className="col-xl-3 col-lg-4 col-md-6">            
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.1s">
            <div className="member" >
              <img  src="assets/img/team/team-1.jpeg" style={{  borderRadius: "10px",border: "2px solid var(--theme-light-color)" }} className="img-fluid" alt=""/>
              <div className="text- my-2 border border border-2 rounded py-2 bg-light">
                  <h4 className="text-theme">Jay Surani</h4>
                  <span className="text-theme">Founder</span>
                </div>
              <div className="member-info">
                <div className="member-info-content ">
                  <h4>Jay Surani</h4>
                  <span>Founder</span>
                  <div className="social">
                  <a href="https://wa.link/qb8yhm"><i className="bi bi-whatsapp"></i></a>
                  <a href="tel:8160911847"><i className="bi bi-phone"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.2s">
            <div className="member">
              <img src="assets/img/team/team-4.jpg" className="img-fluid" alt="" style={{  borderRadius: "10px",border: "2px solid var(--theme-light-color)" }}/>
              <div className="member-info">
                <div className="member-info-content">
                  <h4> Ashish Sanghani</h4>
                  <span>Founder</span>
                  <div className="social">
                  <a href="https://wa.link/5l4mbt"><i className="bi bi-whatsapp"></i></a>
                  <a href="tel:75748 96950"><i className="bi bi-phone"></i></a>
                  </div>
                </div>
              </div>
              <div className="text- my-2 border border border-2 rounded py-2 bg-light">
                  <h4 className="text-theme">Ashish Sanghani</h4>
                  <span className="text-theme">Founder</span>
                </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.3s">
         
          </div>

        </div>

      </div>
    </section> 
        </>
    );
}
export default Team;