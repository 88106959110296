import React from "react";
import Service from "./Service";
function Aboutus() {
    return(
        <>
      <section id="about" className="about animate__animated animate__fadeInDown" style={{backgroundColor:"white"}}>
      <div className="container">

        <div className="row no-gutters">
          <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"></div>
          <div className="col-xl-7 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch">
            <div className="content  d-flex flex-column justify-content-center">
            <div className="container">
              <h3>Why  Choose Latest Corporation ? </h3>
              <p>
              Latest Corporation Kitchen And Brass Products has been manufacturing and supplying OEMs with products made from quality materials. We create custom products from many materials such as Brass,  copper and more. Every component that is manufactured or supplied is produced utilizing specific material required for each customer's application.
              </p>
              </div>
              <div className="row container">
                <div className="col-md-6 icon-box">
                  <div className="border p-2 border-2 rounded bg-light" >
                  <i className="bx bx-receipt"></i>
                  <h4>PROFESSIONAL  WORKERS</h4>
                  <p>We have professional workers in manufacturing plant.</p>
                  </div>
                </div>
                <div className="col-md-6 icon-box">
                  <div className="border p-2 border-2 rounded bg-light" >
                  <i className="bx bx-cube-alt"></i>
                  <h4>EXCELLENCE IN DESIGN</h4>
                  <p>By working with some of India’s leading designers, we understand the latest trends. </p>
                  </div>
                </div>
                <div className="col-md-6 icon-box">
                  <div className="border p-2 border-2 rounded bg-light" >

                  <i className="bx bx-images"></i>
                  <h4>ON TIME DELIVERY</h4>
                  <p>We provide on time delivory of the sink as we talked.</p>
                  </div>
                </div>
                <div className="col-md-6 icon-box">
                  <div className="border p-2 border-2 rounded bg-light" >

                  <i className="bx bx-shield"></i>
                  <h4>LATEST TECHNOLOGY</h4>
                  <p>We have latest technology installed in our plants.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
   
      <Service/>
      <hr/>
    <section id="why-us" className="why-us animate__animated animate__fadeInDown" style={{backgroundColor:"white"}}>
      <div className="container">

        <div className="section-title">
          <h2>Why Choose Us ?</h2>
          <p>Our globally recognized enterprise works in a well planned and organized way by following a customer-focused policy, which helps to make our clients happy and satisfied. All our business plans are made by senior most professional of the company keeping in view expectations of customers. Also, the products like  Kitchen sink , Brass, etc... offered by us with quality assured and made of high quality raw material. </p>
        </div>

        <div className="row">

          <div className="col-lg-4">
            <div className="box">
              <span>01</span>
              <h4>Business deals</h4>
              <p>Honest and transparent business deals</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box">
              <span>02</span>
              <h4>Discounts</h4>
              <p>Discounts that we offer to our clients depending upon the order size.</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box">
              <span>03</span>
              <h4> Professionals</h4>
              <p>Unit of highly dexterous professionals that is working for us.</p>
            </div>
          </div>

        </div>

      </div>
    </section>
        </>
    );
}
export default Aboutus;