import React from "react";
import Aboutus from "./Aboutus";
import Slider from "./Slider";
function Home() {
  var x = window.matchMedia("(max-width: 990px)");
    return(
        <>
            <div className={x.matches+' jn'} style={x.matches ? { marginTop: '100px' } : {}}>

        <Slider/>
            </div>
        <Aboutus/>
        
        </>
    );
}
export default Home;